import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StyledCard = styled.div`
  position: relative;

  position: ${props => props.absolute && "absolute"};
  top: ${props => props.absolute && "0"};
  left: ${props => props.absolute && "0"};
  right: ${props => props.absolute && "0"};
  width: ${props => props.absolute && "100%"};
  height: ${props => props.absolute && "100%"};

  margin: ${props => props.margin && props.margin};

  display: ${props => props.flex && "flex"};
`;

export const Card = ({ children, ...rest }) => (
  <StyledCard {...rest}>{children}</StyledCard>
);

Card.propTypes = {
  children: PropTypes.node
};
