import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import theme from "../../styles/theme";
import { Typography } from "./Typography";

export const LinkTypography = styled(Typography)`
  position: relative;
  display: inline-block;

  a {
    color: ${theme.color.black};
    font-size: ${theme.font.display6};
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1rem;
    text-transform: uppercase;
    border-bottom: none;
    position: relative;
    text-align: inherit;

    &::after {
      width: 0rem;
      display: block;
      content: "";
      position: absolute;
      bottom: -5px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      transition: width 0.3s linear;
    }

    &:hover {
      color: ${theme.color.black};
      border: none;
      border-bottom-color: ${theme.color.black};
      padding: 0;

      &::after {
        border-bottom-color: inherit;
        width: 100%;
      }
    }
  }
`;

export const Link = ({ children, ...rest }) => (
  <LinkTypography {...rest}>{children}</LinkTypography>
);

Link.propTypes = {
  children: PropTypes.node.isRequired
};
