import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Card } from "../elements/card";
import { Typography } from "../elements/Typography";

const StyledTextCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 400px;
  margin: auto;

  padding: 0 3rem;
  padding: ${props => props.wideText && "0"};

  @media (max-width: ${theme.device.small}) {
    width: 100%;
    max-width: 100%;
    padding: 0 1rem;
  }
`;

export const TextCard = props => {
  const { data } = props;

  let isWideText;
  if (data.text_width === "narrow") {
    isWideText = false;
  } else {
    isWideText = true;
  }

  return (
    <StyledTextCard wideText={isWideText}>
      <Typography display3 heading2 centre bold noMargin>
        {data.heading}
      </Typography>
      <Typography centre dangerouslySetInnerHTML={{ __html: data.text }} />
    </StyledTextCard>
  );
};
