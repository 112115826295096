import { useStaticQuery, graphql } from "gatsby";
import { LogoGridPanel } from "../components/collections/LogoGridPanel";

export const LogoPanel = () => {
  const { allWordpressAcfOptions } = useStaticQuery(graphql`
    query {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              footer_partners_grid {
                image {
                  title
                  source_url
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 230) {
                        originalImg
                      }
                    }
                  }
                }
                text
              }
            }
          }
        }
      }
    }
  `);

  const logos =
    allWordpressAcfOptions.edges[0].node.options.footer_partners_grid;

  return <LogoGridPanel logos={logos} />;
};
