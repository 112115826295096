/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import "@reach/skip-nav/styles.css";

import SEO from "./seo";
import SkipNavLink from "./SkipNavLink";
import theme from "../styles/theme";
import reset from "../styles/reset";

import "typeface-poppins";
import { LogoGridPanel } from "./collections/LogoGridPanel";
import { SiteTitle } from "./elements/SiteTitle";
import { Menu } from "./collections/Menu";
import { ModalProvider } from "./containers/modalProvider";

import "../static/styles/normalize.css";

import "../static/styles/global.css";
import "../static/styles/accordion.css";
import "../static/styles/carousel.css";
import "../static/styles/forms.css";
import "../static/styles/video-react.css";
import { MenuPanel } from "../panels/menuPanel";

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.color.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.color.grey.dark};
    background-color: ${theme.color.white};
  }
  ::selection {
    color: ${theme.color.white};
    background-color: ${theme.color.mint.darkish};
  }
  a {
    color: ${theme.color.grey.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 400;
  }
  @media (max-width: ${theme.device.medium}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.device.small}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`;

const PureLayout = ({ children, customSEO, transparentMenu = false }) => (
  <ThemeProvider theme={theme}>
    <ModalProvider>
      <>
        <Global styles={globalStyle} />
        <SiteTitle>Inspire Group</SiteTitle>
        <MenuPanel transparent={transparentMenu} />
        <SkipNavLink />
        {!customSEO && <SEO />}
        {children}
        <LogoGridPanel logos={[]} />
      </>
    </ModalProvider>
  </ThemeProvider>
);

class Layout extends Component {
  render() {
    return (
      // <StaticQuery
      //   query={graphql`
      //     query LayoutQuery {
      //       prismicSiteWideSettings {
      //         data {
      //           google_analytics_id
      //         }
      //       }
      //     }
      //   `}
      //   render={data => (
      <PureLayout {...this.props}>{this.props.children}</PureLayout>
      //   )}
      // />
    );
  }
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  // data: PropTypes.object.isRequired,
  customSEO: PropTypes.bool,
};

PureLayout.defaultProps = {
  customSEO: false,
};
