import React from "react";

import { BuilderCarouselPanel } from "./BuilderCarouselPanel";
import { BuilderTextPanel } from "./BuilderTextPanel";
import { BuilderImagePanel } from "./BuilderImagePanel";
import { BuilderGridPanel } from "./BuilderGridPanel";
import { BuilderParallaxPanel } from "./BuilderParallaxPanel";
import { BuilderColumnsPanel } from "./BuilderColumnsPanel";
import { BuilderAccordionPanel } from "./BuilderAccordionPanel";
import { BuilderPeoplePanel } from "./BuilderPeoplePanel";
import { BuilderCaseStudyGrid } from "./BuilderCaseStudyGrid";

export const Builder = ({ content }) => {
  if (content === null || content === false || content === undefined) {
    return null;
  }

  const c = content.map((item, i) => {
    const layout = item.__typename;

    switch (layout) {
      case "carousel": {
        return <BuilderCarouselPanel data={item} key={i} />;
      }
      case "WordPressAcf_text_block": {
        return <BuilderTextPanel data={item} key={i} />;
      }
      case "WordPressAcf_grid_content": {
        return <BuilderGridPanel data={item} key={i} />;
      }
      case "image": {
        return <BuilderImagePanel data={item} key={i} />;
      }
      case "WordPressAcf_parallax": {
        return <BuilderParallaxPanel data={item} key={i} counter={i} />;
      }
      case "columns": {
        return <BuilderColumnsPanel data={item} key={i} />;
      }
      case "WordPressAcf_accordion": {
        return <BuilderAccordionPanel data={item} key={i} />;
      }
      case "WordPressAcf_people_grid": {
        return <BuilderPeoplePanel key={i} />;
      }
      case "WordPressAcf_case_study_grid": {
        return <BuilderCaseStudyGrid key={i} />;
      }
      default:
        return null;
    }
  });

  return <>{c}</>;
};
