import React from "react";
import styled from "@emotion/styled";

import { Layout } from "../elements/container";
import { Image } from "../elements/Image";

const ImageLayout = styled(Layout)`
  font-size: 0;
`;

export const BuilderImagePanel = ({ data: { image } }) => (
  <ImageLayout>
    <Image data={image} />
  </ImageLayout>
);
