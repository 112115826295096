import React, { Fragment } from "react";

import styled from "@emotion/styled";
import { Layout } from "../elements/container";
import { Typography } from "../elements/Typography";
import theme from "../../styles/theme";

const Lay = styled(Layout)`
  padding: 8rem 0 8rem 0;

  @media (max-width: ${theme.device.medium}) {
    padding: 5rem 0;
  }
`;

export class BuilderTextPanel extends React.Component {
  render() {
    const { data } = this.props;

    let isLargeText;
    if (data.text_size === "normal") {
      isLargeText = false;
    } else {
      isLargeText = true;
    }

    return (
      <Fragment>
        <Lay container narrow>
          <Typography
            h2
            display3
            bold
            centre
            noMargin
            dangerouslySetInnerHTML={{ __html: data.heading }}
          />
          <Typography
            div
            display4={isLargeText}
            centre
            dangerouslySetInnerHTML={{ __html: data.text }}
          />
        </Lay>
      </Fragment>
    );
  }
}
