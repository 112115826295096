import React from "react";
import { CaseStudyPanel } from "../../panels/caseStudyPanel";

export class BuilderCaseStudyGrid extends React.Component {
  render() {
    return (
      <>
        <CaseStudyPanel />
      </>
    );
  }
}
