import React from "react";
import PropTypes from "prop-types";

export class Image extends React.Component {
  render() {
    const {
      source_url,
      width,
      height,
      alt_text,
      title,
      rounded,
      ...rest
    } = this.props;

    return (
      <img
        src={source_url}
        width={width}
        height={height}
        alt={alt_text}
        title={title}
        {...rest}
      />
    );
  }
}

Image.propTypes = {
  source_url: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  alt_text: PropTypes.string,
  title: PropTypes.string
};

Image.defaultProps = {
  width: undefined,
  height: undefined,
  source_url: "",
  alt_text: "",
  title: ""
};
