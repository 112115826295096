import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Layout } from "../elements/container";
import { TextCard } from "./TextCard";

const Grid = styled.div`
  display: flex !important;
  flex-direction: row;
  font-size: 0;
  background: #fff;
  min-height: 400px;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  @media (max-width: ${theme.device.medium}) {
    flex-basis: 100%;
    max-height: 25rem;
  }

  @media (min-width: ${theme.device.largest}) {
    max-height: 72vh;
  }
`;

const ItemWithText = styled(Item)`
  order: 1;
  order: ${props => props.left && "0"};

  @media (max-width: ${theme.device.medium}) {
    order: 1;
    padding: 4rem 0;
  }
`;

const ItemWithImage = styled(Item)`
  overflow: hidden;
  background-color: ${theme.color.grey.lightest};

  @media (max-width: ${theme.device.medium}) {
    order: 0;
    img {
      max-height: 25rem;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  @media (min-width: ${theme.device.largest}) {
    img {
      max-height: 72vh;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
`;

export const BuilderGridPanel = ({ data }) => {
  let img;
  if (
    data.image.localFile.childImageSharp &&
    data.image.localFile.childImageSharp.original.src
  ) {
    img = data.image.localFile.childImageSharp.original.src;
  } else {
    img = "";
  }
  return (
    <Grid>
      {data.layout === "textLeft" ? (
        <ItemWithText left>
          <TextCard data={data} />
        </ItemWithText>
      ) : (
        <ItemWithText>
          <TextCard data={data} />
        </ItemWithText>
      )}
      <ItemWithImage>
        <img src={img} alt="" />
      </ItemWithImage>
    </Grid>
  );
};
