import React from "react";
import styled from "@emotion/styled";
import Carousel from "nuka-carousel";
import "../../static/styles/carousel.css";

import { Layout } from "../elements/container";
import { Image } from "../elements/Image";

// This slide is basically a container - any styling done on internal elements
const Slide = styled.div`
  width: 100%;
  height: auto;
  justify-content: center;
  font-size: 0;
  position: relative;
`;

// This and using state to force render gets around bug in nuka-carousel initial height
function getInitialHeight(data) {
  const container = document.body.clientWidth; // Assuming full width carousel
  const [
    {
      slide: { height, width }
    }
  ] = data;

  return Math.round((height / width) * container);
}

export class BuilderCarouselPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselData: [],
      initialHeight: null
    };
  }

  async componentDidMount() {
    const {
      data: { slides }
    } = await this.props;

    const height = await getInitialHeight(slides);

    await this.setStateAsync({
      carouselData: slides,
      initialHeight: height
    });
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  render() {
    const { carouselData } = this.state;
    const { initialHeight } = this.state;

    const settings = {
      wrapAround: true,
      slidesToShow: 1,
      cellSpacing: 0,
      withoutControls: false,
      transitionMode: "fade",
      heightMode: "first",
      initialSlideHeight: initialHeight
    };

    return (
      <Layout style={{ padding: "3rem 0 0 0" }}>
        <Carousel {...settings}>
          {carouselData.map((item, index) => (
            <Slide key={index}>
              <Image data={item.slide} />
            </Slide>
          ))}
        </Carousel>
      </Layout>
    );
  }
}
