import React, { Component } from "react";
import styled from "@emotion/styled";

const BaseModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
`;

export const ModalContext = React.createContext({});

export class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalNode: null,
      BackgroundComponent: BaseModalBackground
    };

    this.setModalNode = this.setModalNode.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.backgroundComponent !== prevState.BackgroundComponent &&
      nextProps.backgroundComponent
    ) {
      return { BackgroundComponent: nextProps.backgroundComponent };
    }

    return null;
  }

  setModalNode(node) {
    this.setState({ modalNode: node });
  }

  render() {
    return (
      <ModalContext.Provider
        value={{
          modalNode: this.state.modalNode,
          BackgroundComponent: this.state.BackgroundComponent
        }}
      >
        {this.props.children}
        <div ref={this.setModalNode} />
      </ModalContext.Provider>
    );
  }
}
