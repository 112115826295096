import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: ${theme.device.medium}) {
    height: 100%;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoInBG = props => (
  <VideoContainer>
    <Video autoPlay loop muted playsInline>
      <source src={props.src} />
    </Video>
  </VideoContainer>
);
