import React from "react";

import { PeoplePanel } from "../../panels/peoplePanel";

export class BuilderPeoplePanel extends React.Component {
  render() {
    return (
      <>
        <PeoplePanel />
      </>
    );
  }
}
