import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { Layout } from "../components/elements/container";
import { GridItemWithTransition } from "../components/collections/GridItem";
import theme from "../styles/theme";

const Grid = styled.ul`
  margin: 0;
  margin-bottom: 128px;

  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2px 1fr 2px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;

  @media (min-width: ${theme.device.small}) {
    -ms-grid-columns: 1fr 2px 1fr 2px 1fr 2px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: ${theme.device.large}) {
    -ms-grid-columns: 1fr 2px 1fr 2px 1fr 2px 1fr 2px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: ${theme.device.largest}) {
    -ms-grid-columns: 1fr 2px 1fr 2px 1fr 2px 1fr 2px 1fr 2px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

const Item = styled.li`
  height: 100%;
  width: 100%;
  position: relative;
  font-size: 0;
  margin 0;

  &::before {
    content: " ";
    float: left;
    display: inline-block;
    width: 1px;
    margin-left: -1px;
    height: 0px;
    padding-top: 100%;
  }
`;

export const CaseStudyPanel = () => {
  const {
    allWordpressWpCaseStudy: { nodes },
  } = useStaticQuery(graphql`
    {
      allWordpressWpCaseStudy {
        nodes {
          title
          acf {
            case_study_description
          }
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 500, height: 500) {
                  src
                }
              }
            }
          }
          slug
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <Grid>
          {nodes.map((item, i) => (
            <Item key={i}>
              <GridItemWithTransition
                data={item}
                to={`/case-study/${item.slug}`}
                description={item.acf.case_study_description}
              />
            </Item>
          ))}
        </Grid>
      </Layout>
    </>
  );
};
