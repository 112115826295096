import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import theme from "../../styles/theme";

import { Layout } from "../elements/container";
import { List, Item } from "../elements/List";
import { Typography } from "../elements/Typography";
import { PrimaryLogo } from "../elements/iconLogo";
import parse from "url-parse";


const StyledFooter = styled(Layout)`
  display: flex;
  background-color: ${theme.color.grey.lightest};
  background-color: rgba(230, 231, 239, 0.3);
  background-color: #f8f8fa; // Temp hack
  padding: 2rem 0 4rem 0;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }
`;

const FooterList = styled(List)`
  li {
    line-height: 1.8rem;
  }

  @media (max-width: ${theme.device.small}) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Logo = styled(PrimaryLogo)`
  height: auto;
  width: 160px;

  color: ${theme.color.black};
  transition: fill 0.3 ease-in-out;
`;

const LogoText = styled.span`
  display: none;
  font-size: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 50%;

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
    margin: auto;
    text-align: center;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: space-between;

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }
`;

const Container = styled.div`
  display: flex;
  margin: 1rem;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }
`;

export const Footer = () => {
  const { allWordpressMenusMenusItems: { edges } } = useStaticQuery(graphql`
    query {
      allWordpressMenusMenusItems(filter:{ slug: { in: ["footer-left-menu", "footer-centre-menu", "footer-right-menu"]}}) {
        edges {
          node {
            id
            name
            slug
            items {
              title
              url
            }
          }
        }
      }
    }
  `);

  const leftMenu = edges.find(r => r.node.slug === "footer-left-menu")
  const centerMenu = edges.find(r => r.node.slug === "footer-centre-menu")
  const rightMenu = edges.find(r => r.node.slug === "footer-right-menu")

  console.log(edges)

  return (<StyledFooter className="" container>
    <Container>
      <LogoContainer>
        <Link to="/" style={{ marginBottom: '2rem' }}>
          <Logo />
          <LogoText>Inspire Group</LogoText>
        </Link>
        <Typography component="p" style={{ fontSize: '0.9em', lineHeight: '1.4em' }}>
          Inspire Group 2018-2019
          <br />
          Copyright &copy;
        </Typography>
      </LogoContainer>
      <MenuContainer>
        <FooterList block>
          {leftMenu ? <><Item>
            <Typography bold noMargin component="p">
              {leftMenu.node.name}
            </Typography>
          </Item>
            {leftMenu.node.items.map(item => {
              return (
                <Item key={`left-${parse(item.url, {}).pathname}`}>
                  <Link to={parse(item.url, {}).pathname}>{item.title}</Link>
                </Item>
              )
            })} </> : null}
        </FooterList>
        <FooterList block>
          {centerMenu ? <><Item>
            <Typography bold noMargin component="p">
              {centerMenu.node.name}
            </Typography>
          </Item>
            {centerMenu.node.items.map(item => {
              return (
                <Item key={`center-${parse(item.url, {}).pathname}`}>
                  <Link to={parse(item.url, {}).pathname}>{item.title}</Link>
                </Item>
              )
            })}</> : null}
        </FooterList>
        <FooterList block>
          {rightMenu ? <>
            <Item>
              <Typography bold noMargin component="p">
                {rightMenu.node.name}
              </Typography>
            </Item>
            {rightMenu.node.items.map(item => {
              return (
                <Item key={`right-${parse(item.url, {}).pathname}`}>
                  <Link to={parse(item.url, {}).pathname}>{item.title}</Link>
                </Item>
              )
            })}
          </> : null
          }
        </FooterList>
      </MenuContainer>
    </Container>
  </StyledFooter >
  )
};
