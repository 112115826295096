// https://github.com/AlexanderRichey/styled-react-modal

import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { ModalContext } from "./modalProvider";

export class Modal extends Component {
  static styled() {
    const styles = styled.div``;
    return () => <Modal WrapperComponent={styles} {...this.props} />;
  }

  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.node = null;
    this.prevBodyOverflow = null;

    this.onKeydown = this.onKeydown.bind(this);
    this.onBackgroundClick = this.onBackgroundClick.bind(this);
    this.cleanUp = this.cleanUp.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle state changes
    if (prevState.isOpen !== this.state.isOpen) {
      if (!this.state.isOpen) {
        this.cleanUp();

        if (this.props.afterClose) {
          this.props.afterClose();
        }
      } else if (this.state.isOpen) {
        document.addEventListener("keydown", this.onKeydown);

        if (!this.props.allowScroll) {
          this.prevBodyOverflow = document.body.style.overflow;
          document.body.style.overflow = "hidden";
        }

        if (this.props.afterOpen) {
          this.props.afterOpen();
        }
      }
    }

    // Handle prop changes
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.handleChange("beforeOpen", { isOpen: true });
      } else {
        this.handleChange("beforeClose", { isOpen: false });
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) this.cleanUp();
  }

  onBackgroundClick(e) {
    if (this.node === e.target && this.props.onBackgroundClick) {
      this.props.onBackgroundClick(e);
    }
  }

  onKeydown(e) {
    if (e.key === "Escape" && this.props.onEscapeKeydown) {
      this.props.onEscapeKeydown(e);
    }
  }

  cleanUp() {
    document.removeEventListener("keydown", this.onKeydown);

    if (!this.props.allowScroll) {
      document.body.style.overflow = this.prevBodyOverflow || "";
    }
  }

  handleChange(event, newState) {
    if (this.props[event]) {
      try {
        this.props[event]().then(() => this.setState(newState));
      } catch (e) {
        this.setState(newState);
      }
    } else {
      this.setState(newState);
    }
  }

  render() {
    const { WrapperComponent, children, ...rest } = this.props;
    const { isOpen } = this.state;

    let content;
    if (WrapperComponent) {
      content = <WrapperComponent {...rest}>{children}</WrapperComponent>;
    } else {
      content = children;
    }

    return (
      <ModalContext.Consumer>
        {ps => {
          const { modalNode, BackgroundComponent } = ps;

          if (modalNode && BackgroundComponent && isOpen) {
            return ReactDOM.createPortal(
              <BackgroundComponent
                onClick={this.onBackgroundClick}
                innerRef={node => {
                  this.node = node;
                }}
              >
                {content}
              </BackgroundComponent>,
              modalNode
            );
          }

          return null;
        }}
      </ModalContext.Consumer>
    );
  }
}
