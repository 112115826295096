import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { FadeIn } from "../containers/lazyLoad";

import theme from "../../styles/theme";
import { Card } from "../elements/card";
import { Typography } from "../elements/Typography";

import placeholder from "../../static/images/placeholder-square.png";

const ItemText = styled(Card)`
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${theme.color.grey.lightest};
  transition: transform 0.8s ease-in-out;
`;

const GridCard = styled(Card)`
  overflow: hidden;

  &:hover ${ItemText} {
    opacity: 1;
  }
  &:hover ${ItemImage} {
    transform: scale(1.15);
  }
`;

export const GridItem = ({ data, title, img, description, to }) => {
  const wp = data;

  let image; // Get featured image or else use placeholder
  if (
    !wp.featured_media ||
    !wp.featured_media.localFile ||
    !wp.featured_media.localFile.childImageSharp
  ) {
    image = placeholder;
  } else {
    image = wp.featured_media.localFile.childImageSharp.fixed.src;
  }

  return (
    <Link to={to}>
      <GridCard>
        <ItemImage
          src={img || image}
          width="500"
          height="500"
          alt="Inspire Group"
        />
        <ItemText absolute>
          <Typography bold colorWhite centre noMargin paddingLR>
            {title || wp.title}
          </Typography>
          <Typography display6 colorWhite centre noMargin paddingLR>
            {description}
          </Typography>
        </ItemText>
      </GridCard>
    </Link>
  );
};

export const GridItemWithTransition = GridItem;
