import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import ScrollVisibilityHeader from "../containers/ScrollVisibilityHeader";

import theme from "../../styles/theme";
import { NavMenuList } from "./NavMenuList";
import { PrimaryLogo } from "../elements/iconLogo";
import { MenuInModal } from "./MenuInModal";

const Logo = styled(PrimaryLogo)`
  height: auto;
  width: 140px;

  color: ${theme.color.black};
  transition: all 0.3 ease-in-out;
  margin-left: 2rem;

  &:hover {
    color: ${theme.color.mint.primary};
  }
`;

const LogoText = styled.span`
  display: none;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MenuContainer = styled.div`
  position: relative;
  background-color: ${theme.color.white};

  & .headroom {
    border-color: ${theme.color.white};
    background-color: ${theme.color.white};

    &.headroom--unfixed {
    }
    &.headroom--unpinned {
      background-color: ${theme.color.white};
    }
    &.headroom--pinned {
      background-color: ${theme.color.white};
    }
    & .background--white {
      background-color: ${theme.color.white};
    }
  }

  ${props =>
    props.transparent &&
    `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .headroom {
      background-color: transparent;
    }
  `}

  ${props =>
    props.transparent &&
    `
    & .headroom {
      border-color: ${theme.color.black};
      &.headroom--unfixed {
        border-color: ${theme.color.black};
      }

      &.headroom--unpinned {
        border-color: ${theme.color.white};
      }
       &.headroom--pinned {
        border-color: ${theme.color.white};
      }
    }
  `}
`;

const MenuBar = styled.header`
  height: 100%;
`;

const MenuBarContent = styled.nav`
  max-width: ${theme.layout.width};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const MobileMenu = styled.div`
  align-items: center;

  @media (min-width: ${theme.device.medium}) {
    display: none;
  }

  @media (max-width: ${theme.device.medium}) {
    display: flex;
  }
`;

export class Menu extends React.Component {
  render() {
    return (
      <MenuContainer {...this.props}>
        <ScrollVisibilityHeader disableInlineStyles>
          <MenuBar>
            <MenuBarContent>
              <LogoContainer>
                <Link to="/">
                  <Logo />
                  <LogoText>Inspire Group</LogoText>
                </Link>
              </LogoContainer>
              <NavMenuList menu={this.props.menu} />
              <MobileMenu>
                <MenuInModal data={[]} />
              </MobileMenu>
            </MenuBarContent>
          </MenuBar>
        </ScrollVisibilityHeader>
      </MenuContainer>
    );
  }
}

Menu.propTypes = {
  menu: PropTypes.any.isRequired,
};
