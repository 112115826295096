import React from "react";

export const Cross = props => (
  <svg width={23} height={24} viewBox="0 0 23 24" {...props}>
    <title>cross</title>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M20.022 21.77l1.308 1.308 1.415-1.414-1.309-1.309L3.311 2.23 2.003.922.589 2.336l1.308 1.309z" />
      <path d="M3.311 21.77l-1.308 1.308-1.414-1.414 1.308-1.309L20.022 2.23 21.33.922l1.415 1.414-1.309 1.309z" />
    </g>
  </svg>
);
