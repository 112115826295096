import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Ul = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
`;

// Inline list by default
const Inline = styled(Ul)`
  li {
    display: inline;
  }
`;

const Block = styled(Ul)`
  display: block;
`;

const Flex = styled(Ul)`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

export const Item = ({ children, className="" }) => (
  <li {...{ className }} style={{ margin: 0 }}>
    {children}
  </li>
);

Item.propTypes = { children: PropTypes.node.isRequired };

export const List = props => {
  const { block, flex, children, ...rest } = props;

  if (block) {
    return <Block {...rest}>{children}</Block>;
  }

  if (flex) {
    return <Flex {...rest}>{children}</Flex>;
  }

  return <Inline {...rest}>{children}</Inline>;
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
  flex: PropTypes.bool
};

List.defaultProps = {
  block: false,
  flex: true
};
