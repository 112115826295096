import React, { Fragment } from "react";
import styled from "@emotion/styled";
import Plx from "react-plx";

import theme from "../../styles/theme";
import * as parallax from "../../static/data/parallaxData";

const Viewport = styled.section`
  width: 100vw;
  height: 100vh;
  display: block;
  position: relative;
  overflow: hidden;

  @media (max-width: ${theme.device.small}) {
    height: 50vh;
  }
`;

const Parallax = styled.div`
  width: 100vw;
  height: 100vh;
`;

const ParallaxWithBG = styled(Parallax)`
  background-repeat: no-repeat;
  background-size: 100%;
`;

const ParallaxLayer1 = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: ${theme.device.small}) {
      object-fit: contain;
    }
  }
`;

const ParallaxLayer2 = styled(ParallaxLayer1)`
  z-index: -2;
`;

const ParallaxLayer = styled(Plx)`
  z-index: -1;
  height: 100%;
  width: 100%;

  @media (max-width: ${theme.device.small}) {
    height: 50vh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    @media (max-width: ${theme.device.small}) {
      object-fit: contain;
    }
  }
`;

const ParallaxFixedLayer = styled(Plx)`
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  visibility: hidden;

  &.Plx--active {
    visibility: visible;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: fixed;
    top: 0;
  }
`;

export class BuilderParallaxPanel extends React.Component {
  plxStart = counter => {
    const img = document.querySelector(`img.plxImg${counter}`);
    img.style.opacity = 1;
  };

  plxEnd = counter => {
    const img = document.querySelector(`img.plxImg${counter}`);
    img.style.opacity = 0;
  };

  render() {
    const { data, counter } = this.props;
    let isSingleLayer;
    let isFixed;

    if (data.choose_layers === "three") {
      isSingleLayer = false;
    } else {
      isSingleLayer = true;
    }

    if (data.choose_fixed !== "fixed") {
      isFixed = false;
    } else {
      isFixed = true;
    }

    const bgImg =
      data.image_bg.localFile && data.image_bg.localFile.childImageSharp
        ? data.image_bg.localFile.childImageSharp.original.src
        : null;

    return (
      <Fragment>
        <Viewport style={{ zIndex: `-${counter}` }}>
          <Parallax className={`parallax${counter}`}>
            {!isFixed ? (
              <ParallaxLayer parallaxData={parallax.singleLayer}>
                <img
                  src={bgImg}
                  alt={data.image_bg.alt}
                  title={data.image_bg.title}
                />
              </ParallaxLayer>
            ) : (
              <ParallaxFixedLayer
                parallaxData={parallax.fixed}
                onPlxStart={this.plxStart.bind(this, counter)}
                onPlxEnd={this.plxEnd.bind(this, counter)}
              >
                <img
                  className={`plxImg${counter}`}
                  src={bgImg}
                  alt={data.image_bg.alt}
                  title={data.image_bg.title}
                />
              </ParallaxFixedLayer>
            )}
          </Parallax>
        </Viewport>
      </Fragment>
    );
  }
}
