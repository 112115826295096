import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Layout } from "../elements/container";
import { Image } from "../elements/Image";

const Panel = styled(Layout)`
  background-color: ${theme.color.white};
  font-size: 0;
`;

const Grid = styled.ul`
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-columns: 1fr 200px 200px 200px 200px 1fr;

  border: 1px solid ${theme.color.grey.lightest};
  margin: 0;

  @media (max-width: ${theme.device.large}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: ${theme.device.small}) {
    grid-template-columns: 50% 50%;
  }
`;

const Media = styled.div`
  height: 124px
  width: 200px;
`;

const Item = styled.li`
  background-color: ${theme.color.white};
  margin: 0;
  border-left: 1px solid ${theme.color.grey.lightest};
  border-bottom: 1px solid ${theme.color.grey.lightest};

  ${Media} {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 124px;

    img {
      align-self: center;
    }

    @media (max-width: ${theme.device.large}) {
      width: 100%;
    }
  }

  &:nth-of-type(6n + 1) ${Media} {
    float: right;

    @media (max-width: ${theme.device.large}) {
      float: none;
    }
  }

  &:nth-of-type(6n) ${Media} {
    float: left;
    @media (max-width: ${theme.device.large}) {
      float: none;
    }
  }
`;

export const LogoGridPanel = props => {
  const { logos = [] } = props;

  return (
    <Panel>
      <Grid>
        {logos
          .filter(i => i.image)
          .map((item, i) => (
            <Item key={i}>
              <Media>
                {item.image.localFile &&
                item.image.localFile.childImageSharp ? (
                  <Image
                    src={item.image.localFile.childImageSharp.fluid.originalImg}
                  />
                ) : (
                  <Image src={item.image.source_url} />
                )}
              </Media>
            </Item>
          ))}
      </Grid>
    </Panel>
  );
};
