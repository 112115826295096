import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import parse from "url-parse";
import theme from "../../styles/theme";
import { List } from "../elements/List";
import { LinkTypography } from "../elements/Link";

const StyledNavMenuList = styled(List)`
  margin: 0;
  margin-right: 2rem;
  display: flex;

  & li {
    margin-right: 2rem;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${theme.device.medium}) {
    display: none;
  }
`;

export class NavMenuList extends React.Component {
  render() {
    const { menu } = this.props;

    return (
      <StyledNavMenuList>
        {menu.map((item, i) => (
          <li key={i}>
            <LinkTypography>
              <Link to={parse(item.url, {}).pathname}>{item.title}</Link>
            </LinkTypography>
          </li>
        ))}
      </StyledNavMenuList>
    );
  }
}
