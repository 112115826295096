import React, { Fragment } from "react";
import styled from "@emotion/styled";
import Plx from "react-plx";
import Img from "gatsby-image";
import theme from "../../styles/theme";

const parallaxMainImage = [
  {
    start: "self",
    duration: "500px",
    properties: [
      {
        startValue: 0,
        endValue: 11,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
];

const parallaxBlurredImage = [
  {
    start: "self",
    duration: "500px",
    properties: [
      {
        startValue: 0,
        endValue: 11,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const Viewport = styled.section`
  width: 100%;
  height: 500px;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 0;
  @media (max-width: ${theme.device.medium}) {
    height: 250px;
  }
`;

const Parallax = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  ${"" /* background-color: ${theme.color.mint.primary}; */}
  z-index: 1;
`;

const ParallaxMainImage = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: auto;
  width: 100%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    ${"" /* transform: scale(1.02); */}
  }
`;

const ParallaxBlurredImage = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: auto;
  width: 100%;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center top;
    ${"" /* transform: scale(1.02); */}
  }
`;

export const HeaderParallax = props => {
  const { image, blurredImage } = props;

  return (
    <Viewport>
      <Parallax className="parallax">
        {!blurredImage && (
          <ParallaxMainImage parallaxData={parallaxMainImage}>
            <img src={image.src} alt={image.alt_text} title={image.title} />
          </ParallaxMainImage>
        )}
        {blurredImage && (
          <Fragment>
            <ParallaxMainImage parallaxData={parallaxMainImage}>
              <img
                src={image.source_url}
                alt={image.alt_text}
                title={image.title}
              />
            </ParallaxMainImage>
            <ParallaxBlurredImage parallaxData={parallaxBlurredImage}>
              <img
                src={image.source_url}
                alt={image.alt_text}
                title={image.title}
              />
            </ParallaxBlurredImage>
          </Fragment>
        )}
      </Parallax>
    </Viewport>
  );
};
