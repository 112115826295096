import React from "react";
import styled from "@emotion/styled";
import { FadeIn } from "../containers/lazyLoad";

import theme from "../../styles/theme";
import { Card } from "../elements/card";
import { Typography } from "../elements/Typography";

import placeholder from "../../static/images/placeholder-square.png";

const ItemOverlay = styled(Card)`
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ItemText = styled(Card)`
  display: block;
  width: 100%;
  height: 100%;
`;

const TextCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ItemImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  background-color: ${theme.color.grey.lightest};
`;

const GridCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover ${ItemOverlay} {
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
`;

export const GridItemPeople = props => {
  const { data } = props;

  let imageMain = "";
  let imageHover = "";

  if (
    data.acf.people_main_image.localFile &&
    data.acf.people_main_image.localFile.childImageSharp
  ) {
    imageMain =
      data.acf.people_main_image.localFile.childImageSharp.original.src;
  }

  if (
    data.acf.people_hover_image.localFile &&
    data.acf.people_hover_image.localFile.childImageSharp
  ) {
    imageHover =
      data.acf.people_hover_image.localFile.childImageSharp.original.src;
  }

  return (
    <>
      <GridCard>
        <ItemImage src={imageMain} alt="Inspire Group" />
        <ItemOverlay>
          <ItemImage src={imageHover} alt="Inspire Group" />
          <ItemText>
            <TextCard>
              <Typography bold colorWhite centre noMargin paddingLR>
                {data.title}
              </Typography>
              <Typography display6 colorWhite centre noMargin paddingLR>
                {data.acf.people_position}
              </Typography>
            </TextCard>
          </ItemText>
        </ItemOverlay>
      </GridCard>
    </>
  );
};

export const GridItemWithTransition = props => (
  <>
    <GridItemPeople data={props.data} />
  </>
);
