import React from "react";
import { withFormik, Field } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import styled from "@emotion/styled";

import { Button } from "../elements/button";
import { Layout } from "../elements/container";
import { Typography } from "../elements/Typography";

import "../../static/styles/forms.css";

const postUrl =
  "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";

const formikWrapper = withFormik({
  // Need to wrap textarea input with error wrapper before setting as required
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .min(1, "Your name is required")
      .required("Your name is required."),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    // message: Yup.string()
    //   .min(3, "Please provide a short message")
    //   .required("Please provide a short message."),
  }),

  mapPropsToValues: ({ user }) => ({
    ...user,
  }),

  handleSubmit: (payload, { setSubmitting, resetForm }) => {
    console.log(payload);
    const form = document.querySelector("#contactForm");
    const formTitle = document.querySelector("#formGreeting");
    const formResponse = document.querySelector("#formResponse");


    (() => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", postUrl, true);
      xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      xhr.send(JSON.stringify(payload, null, 2));
      setSubmitting(false);

      xhr.onloadend = response => {
        // Use CSSTransitionGroup for something nicer

        if (response.target.status === 200) {
          resetForm();
          formTitle.classList.add("isAnimatedHidden");
          form.classList.add("isAnimatedHidden");
          formResponse.innerHTML =
            "Thanks for the message. We’ll be in touch shortly.";
        } else {
          resetForm();
          // formResponse.innerHTML = "Something went wrong";
          // console.error(JSON.parse(response.target.response).message);
        }
      };
    })();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/json;  charset=UTF-8" },
      body: JSON.stringify(payload, null, 2),
    })
      .then(() => {
        alert("Success!");
        formResponse.innerHTML =
          "Thanks for the message. We’ll be in touch shortly.";
      })
      .catch(error => alert(error));
  },
});

const InputFeedback = ({ error }) =>
  error ? <div className="input-feedback">{error}</div> : null;

const Label = ({ error, children, ...props }) => (
  <label className="label" {...props}>
    {children}
  </label>
);

let TextInput = styled.input`
  width: 100%;
  line-height: 2rem;
  border: none;
`;

TextInput = ({ type, id, label, error, value, onChange, ...props }) => {
  const classes = classnames({
    error: !!error,
  });

  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        className="text-input"
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  );
};

const ContactForm = props => {
  const {
    values,
    touched,
    errors,
    // hidden,
    // dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    // handleReset,
    isSubmitting,
  } = props;

  return (
    <Layout container narrow padding="3rem 0 0 0">
      <Typography display3 bold center>
        Get in touch
      </Typography>
      <Typography id="formGreeting" display2 bold noMargin center>
        Let's see if we can help
      </Typography>
      <Typography id="formResponse" display2 bold noMargin center></Typography>
      <form
        onSubmit={handleSubmit}
        id="contactForm"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <div className="isHidden">
          <TextInput
            name="honeypot"
            type="text"
            value=""
            autoComplete="off"
            tabIndex="-1"
            readOnly
          />
          <input type="hidden" name="oid" value="00D90000000dxsa" />
          <input
            type="hidden"
            name="retURL"
            value="https://inspiregroup.co.nz/contact"
          />
          <input type="hidden" name="form-name" value="contactForm" />
        </div>

        <TextInput
          id="first_name"
          type="text"
          placeholder="First name *"
          error={touched.first_name && errors.first_name}
          value={values.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextInput
          id="last_name"
          type="text"
          placeholder="Last name"
          error={touched.last_name && errors.last_name}
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextInput
          id="email"
          type="email"
          placeholder="Email *"
          error={touched.email && errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Field
          id="description"
          type="textarea"
          component="textarea"
          placeholder="Message *"
          error={touched.description && errors.description}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Button type="submit" onClick={handleSubmit} margin="1rem 0 0">
          {isSubmitting ? "Sending..." : "Contact us"}
        </Button>
      </form>
    </Layout>
  );
};

export default formikWrapper(ContactForm);
