const theme = {
  color: {
    grey: {
      lightmost: "#f8f8fa",
      lightest: "#F1F4FC", // border colour
      light: "#EAEEF6", // rgb(234, 238, 246)
      medium: "#79799c",
      primary: "#555577",
      dark: "#38383A",
    },
    mint: {
      lightest: "#D5F3FF",
      light: "#7ED6ED",
      primary: "#B0E2E2",
      medium: "#79D7DA",
      darkish: "#48627C",
      dark: "#133255",
      darkest: "#18192d",
    },
    pink: "#FF7493",
    yellow: "#FFE799",
    orange: "#FE8460",
    white: "#fff",
    black: "#38383A",
  },
  layout: {
    // Set width of design
    width: "1040px",
    narrow: "780px",
  },
  font: {
    display1: "3.25rem",
    display2: "2.5rem",
    display3: "1.75rem",
    display4: "1.5rem",
    display5: "1.125rem",
    display6: "0.75rem",
  },
  device: {
    // Breakpoints for media queries
    smallest: "480px",
    small: "600px",
    medium: "800px",
    large: "1200px",
    largest: "1600px",
    mega: "2200px",
  },
};

export default theme;
