import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import theme from "../../styles/theme";

const Text = styled.p`
  font-size: 1rem;
  font-size: ${props => (props.display1 || props.size52) && "3.25rem"};
  font-size: ${props => (props.display2 || props.size40) && "2.5rem"};
  font-size: ${props => (props.display3 || props.size28) && "1.75rem"};
  font-size: ${props => (props.display4 || props.size24) && "1.5rem"};
  font-size: ${props => (props.display5 || props.size18) && "1.125rem"};
  font-size: ${props => (props.display6 || props.size12) && "0.75rem"};

  @media (max-width: ${theme.device.medium}) {
    font-size: ${props => (props.display1 || props.size52) && "3.25rem"};
    font-size: ${props => (props.display2 || props.size40) && "1.75rem"};
    font-size: ${props => (props.display3 || props.size28) && "1.5rem"};
    font-size: ${props => (props.display4 || props.size24) && "1.25rem"};
    font-size: ${props => (props.display5 || props.size18) && "1.125rem"};
    font-size: ${props => (props.display6 || props.size12) && "0.75rem"};
  }

  @media (min-width: ${theme.device.medium}) {
    font-size: 1.125rem;
    font-size: ${props => (props.display2 || props.size40) && "2.8125rem"};
    font-size: ${props => (props.display3 || props.size28) && "2rem"};

    line-height: 1.8rem;
    line-height: ${props => (props.display2 || props.size38) && "3.375rem"};
  }

  @media (min-width: ${theme.device.large}) {
    font-size: 1.25rem;
    font-size: ${props => (props.display2 || props.size40) && "2.8125rem"};
    font-size: ${props => (props.display3 || props.size28) && "2.5rem"};

    line-height: 2rem;
    line-height: ${props => (props.display2 || props.size40) && "3.375rem"};
    line-height: ${props => (props.display3 || props.size28) && "3rem"};
  }

  font-weight: normal;
  font-weight: ${props => props.normal && "normal"};
  font-weight: ${props => props.bold && "bold"};

  letter-spacing: ${props => props.spaced && "1px"};
  text-transform: ${props => props.uppercase && "uppercase"};
  text-transform: ${props => props.capitalize && "capitalize"};

  text-align: ${props => (props.centre || props.center) && "center"};

  color: ${theme.color.black};
  color: ${props => (props.colorWhite || props.white) && theme.color.white};
  color: ${props =>
    (props.colorMint || props.colorMintPrimary) && theme.color.mint.primary};
  color: ${props => props.colorMintMedium && theme.color.mint.medium};
  color: ${props => props.colorMintDark && theme.color.mint.dark};
  color: ${props => props.colorGreyLight && theme.color.grey.light};

  color: ${props => props.color && props.color};

  line-height: 1.6rem;
  line-height: ${props => (props.display1 || props.size54) && "4.7rem"};
  line-height: ${props => (props.display2 || props.size38) && "3rem"};
  line-height: ${props => (props.display3 || props.size30) && "2.8rem"};
  line-height: ${props => (props.display4 || props.size24) && "2rem"};
  line-height: ${props => (props.display5 || props.size18) && "1.6rem"};
  line-height: ${props => (props.display6 || props.size18) && "1rem"};
  line-height: ${props => (props.decorative160 || props.size160) && "10rem"};
  line-height: ${props => props.lineHeight && props.lineHeight};

  margin: ${props => (props.margin === false || props.noMargin) && "0"};

  padding: ${props =>
    (props.padding === false || props.paddingLR) && "0 0.5rem"};

  a {
    font-weight: normal;
    font-size: inherit;
    color: ${theme.color.mint.primary};
    border-bottom: 2px solid ${theme.color.mint.primary};

    &:hover {
      border-bottom: 2px solid ${theme.color.mint.primary};
      color: ${theme.color.mint.primary};
      padding-bottom: 0.1rem;
    }
  }
`;

const Tags = {
  p: Text,
  div: Text.withComponent("div"),
  h1: Text.withComponent("h1"),
  h2: Text.withComponent("h2"),
  h3: Text.withComponent("h3"),
  h4: Text.withComponent("h4"),
  h5: Text.withComponent("h5"),
  h6: Text.withComponent("h6")
};

export const Typography = ({ children, component, ...rest }) => {
  const Tag = Tags[component];
  return <Tag {...rest}>{children}</Tag>;
};

Typography.propTypes = {
  children: PropTypes.node,
  component: PropTypes.oneOf(["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"])
};

Typography.defaultProps = {
  component: "p"
};
