import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "../../static/styles/accordion.css";

import { Layout } from "../elements/container";
import { Typography } from "../elements/Typography";

export const BuilderAccordionPanel = ({ data }) => {
  const { panel } = data;

  if (data === (null || false)) {
    return null;
  }

  return (
    <>
      <Layout container style={{ padding: "128px 0 1rem 0" }}>
        <Typography
          display3
          heading2
          bold
          centre
          noMargin
          dangerouslySetInnerHTML={{ __html: "Work with us" }}
        />
      </Layout>
      <Layout container narrow style={{ padding: "0 0 5rem 0" }}>
        <Accordion allowZeroExpanded="true">
          {panel.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Typography
                    className="u-position-relative"
                    as="div"
                    div
                    display5
                    heading3
                    bold
                    noMargin
                    uppercase
                    spaced
                  >
                    <div dangerouslySetInnerHTML={{ __html: item.heading }} />
                    <div className="accordion__arrow" role="presentation" />
                  </Typography>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Typography
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Layout>
    </>
  );
};
