import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Typography } from "../elements/Typography";
import { VideoInBG } from "./VideoInBG";

import { HeaderParallax } from "./HeaderParallax";

const HeaderLayout = styled.header`
  height: 500px;
  width: 100vw;
  position: relative;
  display: block;
  overflow: hidden;
  background-color: ${theme.color.white};
  z-index: 2;

  @media (max-width: ${theme.device.medium}) {
    height: 250px;
  }
`;

const Title = styled(Typography)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  @media (max-width: ${theme.device.medium}) {
    height: 250px;
  }
`;

const ImageInBG = styled.div`
  width: 100%;
  min-height: 100%;
  z-index: 2;

  background-image: url(${props => props.src && props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ImageHeader = props => {
  const { textColor, image, title, isParallax, blurredImage } = props;
  return (
    <HeaderLayout>
      <ImageContainer>
        {isParallax ? (
          <HeaderParallax image={image} blurredImage={blurredImage} />
        ) : (
          <ImageInBG src={image.source_url} />
        )}
      </ImageContainer>

      <Title heading1 display2 bold centre color={textColor}>
        {title}
      </Title>
    </HeaderLayout>
  );
};

export const VideoHeader = props => {
  const { video, title, textColor } = props;
  return (
    <HeaderLayout>
      <VideoInBG src={video} />
      <Title heading1 display2 bold centre color={textColor}>
        {title}
      </Title>
    </HeaderLayout>
  );
};

export const Header = props => {
  const { backgroundType } = props;

  switch (backgroundType) {
    case "image": {
      return <ImageHeader {...props} />;
    }
    case "video": {
      return <VideoHeader {...props} />;
    }
    default: {
      return null;
    }
  }
};
