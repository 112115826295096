module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Inspire Group", // Navigation and Site Title
  titleAlt: "Inspire Group", // Title for JSONLD
  description: `Inspire Group`,
  headline: "Better learning", // Headline for schema.org JSONLD
  url: "https://inspiregroup.com.au", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  logo: "/logos/logo-1024.png", // Used for SEO
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/favicon.ico", // Used for manifest favicon generation
  shortName: "Inspire", // shortname for manifest. MUST be shorter than 12 characters
  author: "Inspire Group", // Author for schemaORGJSONLD
  themeColor: "#3D63AE",
  backgroundColor: "#EBEDF2",

  twitter: "@inspiregroup", // Twitter Username
  facebook: "inspiregroup", // Facebook Site Name
  googleAnalyticsID: "UA-66998455-4",

  skipNavId: "reach-skip-nav" // ID for the "Skip to content" a11y feature
};
