import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import theme from "../../styles/theme";

/*
Row:
- unstyled row of content, full width, no margins
- any BG image or video in RowWithBG
Container:
- centered in middle at max-width from designs
- has margin/padding within row boundary
*/

export const Row = styled.section`
  background-color: ${theme.color.white};
  position: relative;
  width: 100%;
  padding: ${props => props.padding && props.padding};
`;

export const Container = styled.div`
  max-width: ${theme.layout.width};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin: ${props => props.margin && props.margin};
  padding: ${props => props.padding && props.padding};
  max-width: ${props => props.narrow && theme.layout.narrow};
  background-color: transparent;

  @media (max-width: ${theme.device.medium}) {
    padding: 0 1rem;
  }
`;

export const Layout = ({ container, children, className, ...rest }) => {
  if (container) {
    return (
      <Row {...{ className }}>
        <Container {...rest}>{children}</Container>
      </Row>
    );
  }

  return <Row {...rest}>{children}</Row>;
};

Layout.propTypes = {
  container: PropTypes.bool,
  children: PropTypes.node.isRequired
};

Layout.defaultProps = {
  container: false
};
