import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

// Custom project components
import { Typography } from "./Typography";

const Title = styled(Typography)`
  display: none;
`;

export const SiteTitle = ({ children }) => (
  <Title component="h1">{children}</Title>
);

SiteTitle.propTypes = {
  children: PropTypes.node.isRequired
};
