import React from "react";
import { Link } from "gatsby-link";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Layout } from "../elements/container";
import { Card } from "../elements/card";
import { Button } from "../elements/button";
import { List, Item } from "../elements/List";
import { Typography } from "../elements/Typography";

const ColumnsLayout = styled(Layout)`
  padding-top: 4rem;
`;

const ColumnsListCard = styled(Card)``;

const ColumnsList = styled(List)`
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }

  img {
    width: auto;
    height: 140px;
  }

  ${ColumnsListCard} {
    padding: 2rem;

    p {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      margin-top: 2rem;
    }
  }
`;

function getUrlPath(string) {
  const url = new URL(string);
  return url.pathname;
}

export const BuilderColumnsPanel = ({ data: { column } }) => (
  <ColumnsLayout container>
    <ColumnsList>
      {column.map((item, i) => (
        <Item key={i}>
          <ColumnsListCard>
            <img
              src={item.image.url}
              alt={item.image.alt}
              title={item.image.title}
              width="100"
            />
            <Typography bold heading3 display3>
              {item.heading}
            </Typography>
            <Typography noMargin>{item.text}</Typography>
            <Link to={getUrlPath(item.link.url)}>
              <Button>More</Button>
            </Link>
          </ColumnsListCard>
        </Item>
      ))}
    </ColumnsList>
  </ColumnsLayout>
);
