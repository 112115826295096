import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Menu } from "../components/collections/Menu";

export const MenuPanel = ({ transparent = false }) => {
  const {
    wordpressMenusMenusItems: { items },
  } = useStaticQuery(graphql`
    {
      wordpressMenusMenusItems(slug: { eq: "main-menu" }) {
        items {
          target
          title
          url
          object
          object_id
        }
      }
    }
  `);
  return <Menu transparent={transparent} border menu={items} />;
};
